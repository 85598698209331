/* Category */
.page-type-category .qty-wrapper {
	font-size: 1em;
	margin: 5px 0 10px;
}



.page-type-category .products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:55px;
}

